$slick-font-path: "../fonts/";
$slick-loader-path: "../images/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #5d2e16;
$light-brown:           #e3c683;
$dark-brown:            #1d0d01;
$darker-brown:          #39230b;
$brown:                 #4e3a1b;
$faded-brown:           #3a1b08;
$off-black:             #100701;
$red:                   #d31a21;
$white:                 #ffffff;
$black:                 #000000;

// Fonts
$primary-font:          "sorts-mill-goudy",serif; // Regular - 400
$secondary-font:        "myriad-pro",sans-serif; // Regular - 400
$tertiary-font:         "mr-eaves-modern",sans-serif; // Book - 300, Bold - 700, Heavy - 800
$body-font-size:        16px;
$body-font-weight:      400;
$body-font:             $secondary-font;
$body-color:            $light-brown;
$heading-font:          $primary-font;
$heading-color:         $light-brown;
$h1-weight:             400;
$h1-size:               36px;
$h2-weight:             400;
$h2-size:               24px;
$h3-weight:             400;
$h3-size:               22px;

// Base Styles
$base-transition:       0.3s ease-in-out;
$section-padding:       60px;



//Side-Nav
$sideNavWidth: 260px;
$sideNavDirection: (right);

$button_width: 40px;     // width of button area
$button_height: 90px;     // height of button area
$bar_thickness: 5px;     // thickness of button bars
$bar_roundness: 1px;
$button_pad: 0px;      // left/right padding between button area and bars.
$button_bar_space: 9px;     // spacing between button bars
$button_transistion_duration: 0.3s;  // transition duration
$hamburger_normal: $light-brown;
$hamburger_active: $light-brown;



// Breakpoint variables
$width_mobile: 480px;
$width_phablet: 576px;
$width_tablet: 768px;
$width_laptop: 992px;
$width_desktop: 1200px;
$width_hd: 1500px;

/*
 * Mixins
 */
// Media query mixins
@mixin mobile { @media (min-width: #{$width_mobile}) { @content; } }
@mixin phablet { @media (min-width: #{$width_phablet}) { @content; } }
@mixin tablet { @media (min-width: #{$width_tablet}) { @content; } }
@mixin laptop { @media (min-width: #{$width_laptop}) { @content; } }
@mixin desktop { @media (min-width: #{$width_desktop}) { @content; } }
@mixin hd { @media (min-width: #{$width_hd}) { @content; } }


@mixin stretch($offset: 0) {
  position: absolute;
  top: #{$offset};
  right: #{$offset};
  left: #{$offset};
  bottom: #{$offset};
  z-index: 99;
}


@mixin inset-border($height: 0, $color: $brand-primary) {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: $height;
  background-color: $color;
}


@mixin letter-spacing($letter-spacing: 0) {
   letter-spacing: calc(#{$letter-spacing} * 0.001em);
 }


@mixin smooth-fonts() {
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
 }


 // Flexbox mixin - prevents most IE 11 issues
 @mixin flex($grow: 0, $shrink: 0, $basis: 100%) {
   flex: #{$grow} #{$shrink} #{$basis};
   max-width: #{$basis};
 }


 $grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  hd: 1500px
);

.inner-content {
  padding: 50px 0;
  background-color: $faded-brown;

  @include laptop {
    padding: 60px 0;
  }

  h1 {
    margin: 0 0 20px;
  }

  h2 {
    margin: 25px 0 15px;
    font-size: 30px;
    line-height: 28px;
  }

  h3 {
    margin: 20px 0 10px;
    font-size: 24px;
  }

  a {
    border-bottom: 1px solid $light-brown;

    &:hover {
      border-color: $white;
    }
  }

  .alignnone {
    display: block;
    margin: 5px 0;

    @include tablet {
      margin: 0;
    }
  }

  .alignleft, .alignright {
    width: 100%;
    clear: both;

    @include tablet {
      width: 325px;

      &.small {
        width: 200px;
        margin-bottom: 25px;
      }
    }
  }

  .alignleft {
    margin: 0 0 15px;

    @include tablet {
      margin: 0 20px 5px 0;
    }
  }

  .alignright {
    margin: 0 0 15px;

    @include tablet {
      margin: 0 0 5px 20px;
    }
  }

  .content-card, .content-block {
    display: block;
    overflow: auto;
  }

  .content-card {
    width: 100%;
    padding: 25px;
    background-color: $brand-primary;
    margin: 30px 0;
    border: 2px solid darken($brand-primary, 7);

    a:hover {
      color: $white;
      border-color: $white;
    }
  }
}


.step-by-step {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  h2, h3 {
    @include flex(0,0,100%);
  }

  .content-card {
    @include flex(0,0, 100%);
    margin: 10px 0;

    @include laptop {
      @include flex(0,0, 49%);
    }

    img {
      max-width: 100%;

      @include phablet {
        max-width: 225px;
      }
    }

    &.flex-full {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .flex-img {
        @include flex(0,0, 48%);

        img {
          margin: 0 0 5px;
          max-width: 100%;
        }
      }

      p {
        @include flex(0,0,100%);
        margin: 5px 0 0;
      }

      &.flex-large {
        @include flex(0,0,100%);

        .flex-img {
          @include flex(0,0,48%);

          @include tablet {
            @include flex(0,0,32%);
          }
        }
      }
    }
  }
}



.photo-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0;
    margin: 15px -10px 0;

    img {
      display: none;
    }

    a {
      @include flex(0,0,50%)
      padding: 10px;
      border: 0 !important;

      &:hover {
        border: 0 !important;
      }

      @include laptop {
        @include flex(0,0,33.3%);
      }

      @include desktop {
        @include flex(0,0,25%)
      }
    }

    &.small a {
      @include desktop {
        @include flex(0,0,33.3%)
      }
    }

    .gallery-image {
      background-size: cover;
      background-position: center;
      height: 150px;
      position: relative;

      @include phablet {
        height: 250px;
      }

      &:before {
        content: '\f00e';
        @include stretch;
        font-family: FontAwesome;
        font-size: 28px;
        opacity: 0;
        transition: ease-in-out all .3s;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99;
      }

      &:hover:before {
        background-color: rgba(0,0,0,.3);
        opacity: 1;
      }
    }

    #launchGallery {
      cursor: pointer;
      text-decoration: none;

      .text {
        font-size: 18px;
        opacity: 1;
        transition: ease-in-out all .3s;
        text-align: center;

        @include phablet {
          font-size: 22px;
        }
      }

      &:hover {
        .text {
          opacity: 0.1;
        }
      }
    }

    .gallery-more {
      display: flex;
      height: 150px;
      justify-content: center;
      align-items: center;
      background-color: $brand-primary;
      color: $light-brown;

      @include phablet {
        height: 250px;
      }
    }
  }


  .contact-form {


    @include tablet {
      margin-right: 30px;
    }

    .content-card {
      padding: 30px;
    }

    .gform_wrapper {

      input, textarea {
        border: 0;
        width: 100%;
        padding: 7px;
      }

      textarea {
        height: 175px;
      }

      .gform_fields {
        list-style-type: none;
        padding: 0;
        margin: 0;

        .gfield {
          padding: 5px 0;
        }
      }

      input[type="submit"] {
        background-color: $faded-brown;
        color: $light-brown;
        font-family: $primary-font;
        padding: 10px 20px;
        cursor: pointer;
        transition: ease-in-out all .2s;
        margin: 15px 0 0;

        &:hover {
          background-color: darken($faded-brown, 5);
        }
      }

      .validation_error {
        background-color: darken(#790000, 10);
        color: $light-brown !important;
        margin: 0;
        padding: 10px;
      }

      .gfield_required {
        color: red;
      }
    }
  }


#map_canvas {
color: $faded-brown;

  h2 {
    color: $brand-primary;
  }

  a {
    color: $faded-brown;
  }
}

.error404-inner {
  padding: 60px 0;
}

.inner-sidebar {

  @include laptop {
    margin-left: 30px;
  }

  .content-card {
    section {
      padding: 5px 0;

      h3 {
        margin: 0 0 10px;
      }

      li a {
        line-height: 28px;
        border: 0;
      }
    }
  }
}

a.btn {
  display: inline-block;
  padding: 12px 20px 9px;
  background-color: $brand-primary;
  font-family: $primary-font;
  font-size: 18px;
  color: $light-brown;
  border-radius: 0;
  border: 0;
  min-width: 216px;
  @include letter-spacing(50);

  &:hover {
    background-color: darken($brand-primary, 7);
    color: $light-brown;
  }
}

section {
  position: relative;
  padding: 50px 0;

  a {
    color: $light-brown;
    border-bottom: 1px solid $light-brown;

    &:hover {
      color: $white;
      border-color: $white;
    }
  }
}

.bordered-container {
  position: relative;
  border: 1px solid rgba(#5b422e, .7);
  padding: 30px 15px;
  margin: 0 15px;

  @include laptop {
    margin: 0 30px;
    padding: 35px;
  }

  &:before {
    content: '';
    position: absolute;
    top: -10px;
    right: 10px;
    bottom: -10px;
    left: 10px;
    border: 1px solid #5b422e;
  }

  .content {
    position: relative;
    z-index: 99;
  }
}


.welcome-section {
  background-image: url('../images/bg_welcome.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .bordered-container {
    background-color: $faded-brown;
    border-color: rgba(#86604a, .9);

    &:before {
      border-color: rgba(#8f6a55, .9);
    }
  }

  .img-content-block {
    margin: 15px 0 30px;

    @include tablet {
      margin: 30px auto 45px;
    }

    @include hd {
      margin: 0 0 0 65px;
    }

    .welcome-img {
      display: block;
      width: 425px;
      border-radius: 3px;
      box-shadow: 5px 5px 15px rgba($black, .5);
    }

    .btn {
      margin: 30px 0 0;
      width: 100%;
    }
  }

  .welcome-content {

    @include laptop {
      padding: 0 0 0 50px;
    }

    @include hd {
      padding: 0 115px 0 85px;
    }
  }

  p:last-of-type {
    margin: 0;
  }
}



.about-us-section {
  padding: 5px 0;
  background-color: $dark-brown;

  &:before, &:after {
    @include inset-border(2px, $light-brown);
  }

  &:before {
    top: 2px;
  }

  &:after {
    bottom: 2px;
  }

  .service-container {
    overflow: hidden;

    .service-block {
      padding: 30px;
      text-align: center;
      border-bottom: 2px solid $darker-brown;

      @include desktop {
        border-right: 2px solid $darker-brown;
        border-bottom: 0;

      }

      @include hd {
        padding: 45px;
      }

      &.image-block {
        padding: 0;
        border-bottom: 0;
      }

      .featured-image {
        display: block;
        width: calc(100% + 30px);
        height: 250px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin-left: -15px;

        @include tablet {
          width: calc(100% + 90px);
          margin-left: -45px;
        }

        @include desktop {
          position: absolute;
          top: 5px;
          height: calc(100% - 10px);
          margin-left: 0;
          width: 570px;
        }
      }

      .icon {
        margin: 0 0 20px;
        width: 75px;

        @include laptop {
          width: auto;
        }
      }

      h2 {
        line-height: 26px;
        margin: 0 0 20px;

        @include laptop {
          line-height: 24px;
        }
      }

      p {
        margin: 0;
      }
    }

    &.top {

      @include desktop {
        border-bottom: 2px solid $darker-brown;
      }

      .service-block {

        &:last-of-type {
          border-right: 0;
        }

        .featured-image {
          background-image: url('../images/interior_1.jpg');
          right: 5px;
        }
      }
    }

    &.bottom {

      .service-block {

        .featured-image {
          background-image: url('../images/interior_2.jpg');
          left: 5px;
        }
      }
    }
  }
}

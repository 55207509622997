.banner {
  background-color: $black;

  @include desktop {
    padding: 39px 0 0;
  }

  .header-wrapper {
    position: relative;
    background-image: url('../images/header_bg.jpg');
    background-repeat: repeat-x;
    border-top: 2px solid $light-brown;
    border-bottom: 2px solid $light-brown;
    padding: 20px 0;

    @include laptop {
      padding: 30px 0;
    }

    @include desktop {
      height: 147px;
      padding: 40px 0;
    }

    &:before {
      @include inset-border(2px, $brand-primary);
      top: 2px;
    }

    .mobile-header {
      .logo {
        width: 230px;
      }
    }

    // Logo Styles
    .logo-container {
      width: 384px;
      justify-content: center;

      .logo {
        position: relative;
        z-index: 1000;
        top: -79px;
      }
    }

    .nav-container {
      position: relative;
      background-color: $black;
      border-top: 2px solid $light-brown;
      border-bottom: 2px solid $light-brown;
      height: 51px;
      top: 10px;

      &:before, &:after {
        @include inset-border(2px, $brand-primary);
      }

      &:before {
        top: 2px;
      }

      &:after {
        bottom: 2px;
      }

        .nav-top {
          display: block;
          position: absolute;
          top: -38px;
          right: 20px;
          padding: 0;
          margin: 0;

          li {
            display: inline-block;
            margin-left: 30px;

            &:first-child {
              margin: 0;
            }

            a {
              font-size: 15px;

              @include hd {
                font-size: 16px;
              }
            }

            &.phone a {
              color: $light-brown;
            }
          }
        }


      .flex-header {
        display: flex;
        justify-content: center;

        // Navigation Styles
        .left-nav-container, .right-nav-container {
          flex-grow: 1;

          .nav {
            display: flex;

            li {
              @include flex(0,0,auto);
              flex-grow: 1;
              position: relative;
              text-align: center;

              &:after {
                content: '';
                position: absolute;
                top: 6px;
                right: 0;
                height: 35px;
                width: 3px;
                border-left: 1px solid $brown;
                border-right: 1px solid $brown;
              }

              &:last-child:after {
                display: none;
              }

              a {
                font-family: $primary-font;
                color: $light-brown;
                font-size: 11px;
                font-weight: 600;
                line-height: 50px;
                padding: 13px 12px;
                @include letter-spacing(0);
                text-transform: uppercase;

                @include hd {
                  padding: 13px 15px;
                  font-size: 14px;
                  @include letter-spacing(50);
                }

                &:hover {
                  color: $white;
                }
              }

              &.phone a {
                color: $light-brown;
                font-size: 17px;
              }
            }

            &.left {

              @include hd {
                padding-right: 20px;
              }

              li:first-child a {
                padding-left: 0;
              }
            }

            &.right {
              @include hd {
                padding-left: 20px;
              }

              li:last-child a {
                padding-right: 0;
              }
            }
          }
        }
      }
    }
  }
}



.hero {
  position: relative;
  padding: 70px 0;
  background-image: url('../images/hero_home.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @include laptop {
    padding: 140px 0 0;
    min-height: 616px;
  }

  .cta {
    padding: 30px 40px;
    background-color: rgba($black, .25);
    border-color: rgba($white, .4);
    margin: 0;

    @include desktop {
      padding: 50px 45px 50px 55px;
      margin: 0 50px 0 75px;
    }

    &.bordered-container:before {
      background-color: rgba($black, .525);
      border-color: rgba($white, .4);
    }

    p {
      font-family: $primary-font;
    }

    .heading {
      font-size: 32px;
      line-height: 32px;
      margin: 0 0 15px;

      @include laptop {
        font-size: 48px;
        line-height: 48px;
      }
    }

    .content {
      font-size: 18px;
      line-height: 24px;

      @include laptop {
        font-size: 24px;
        line-height: 30px;
      }
    }

    .btn {
      margin: 5px 0 0;

      @include laptop {
        margin: 15px 0 0;
      }
    }
  }

  &.interior-hero {
    padding: 70px 0;

    @include tablet {
      padding: 100px 0;
    }

    @include laptop {
      min-height: 400px;
    }

    &:before {
      @include stretch;
      content: '';
      background-color: rgba($black, .2);
    }

    &.small {
      min-height: 250px;
      z-index: 99;
    }
  }
}


.strapline {
  position: relative;
  padding: 35px 0;
  background-color: $dark-brown;

  &:before {
    @include inset-border(2px, $light-brown);
    top: 2px;
  }

  .bordered-container {
    background-color: $off-black;
    padding: 15px;

    @include laptop {
      padding: 30px;
    }

    &:before {
      top: -7px;
      bottom: -7px;
    }
  }

  p {
    margin: 0;
    color: $light-brown;
    text-align: center;
    font-size: 16px;
    line-height: 23px;

    @include laptop {
      font-size: 24px;
      line-height: 30px;
    }
  }
}

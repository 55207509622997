html, body {
  font-size: $body-font-size;
  font-weight: $body-font-weight;
  font-family: $body-font;
  color: $body-color;
  @include smooth-fonts;
}

img {
  max-width: 100%;
  height: auto;
}

h1, .h1 {
  color: $heading-color;
  font-family: $heading-font;
  font-weight: $h1-weight;
  font-size: 30px;
  line-height: 36px;
  @include letter-spacing(50);

  @include laptop {
    font-size: $h1-size;
  }
}

h2, .h2 {
  color: $heading-color;
  font-family: $heading-font;
  font-weight: $h2-weight;
  font-size: 22px;

  @include laptop {
    font-size: $h2-size;
  }
}

h3, .h3 {
  color: $heading-color;
  font-family: $heading-font;
  font-weight: $h3-weight;
  font-size: 20px;

  @include laptop {
    font-size: $h3-size;
  }
}

p, li {
  font-family: $body-font;
  font-size: $body-font-size;
  font-weight: $body-font-weight;
  line-height: 24px;
  @include letter-spacing(50);
}

p {
  margin-bottom: 25px;
}

a {
  transition: $base-transition;
  color: $light-brown;

  &:hover, &:focus {
    text-decoration: none;
    color: $white;
  }
}

ul.nav {
  list-style-type: none;
  padding: 0;

  .sub-menu {
    list-style-type: none;
    padding: 0;
  }
}


.panel {
  padding: ($section-padding - 20px) 0;
  @include tablet {
    padding: ($section-padding - 10px) 0;
  }
  @include laptop {
    padding: $section-padding 0;
  }
}


@media (min-width: 1200px) {
  .container {
      width: 1560px;
      max-width: 100%;
  }
}


.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("../images/vimeo-play.png") no-repeat scroll 0 0 transparent;
}
.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("../images/vimeo-play.png") no-repeat scroll 0 -58px transparent;
}
.lg-outer .lg-has-html5 .lg-video-play {
  background: transparent url("../images/video-play.png") no-repeat scroll 0 0;
}
.lg-outer .lg-has-youtube .lg-video-play {
  background: url("../images/youtube-play.png") no-repeat scroll 0 0 transparent;
}
.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("../images/youtube-play.png") no-repeat scroll 0 -60px transparent;
}
.lg-outer .lg-item {
  background: url("../images/loading.gif") no-repeat scroll center center transparent;
}

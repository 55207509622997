.sideNav {
    width: $sideNavWidth;
    #{$sideNavDirection}: -$sideNavWidth;
    position: fixed;
    z-index: 1000;
    height: 100%;
    top: 0;
    background: $faded-brown;
    transition: all 0.3s ease;
    border-top: 2px solid $light-brown;
    border-left: 2px solid $light-brown;

    a {
        display: block;
        color: #fff;
        font-size: 16px;
        border-bottom: 1px solid darken($brand-primary, 5);
        padding: 15px 20px;
    }

    &.sideNav-open {
        #{$sideNavDirection}: 0px;
    }
}

/* classes applied to the body */
.sideNavBody {
    overflow-x: hidden;
    position: relative;
    #{$sideNavDirection}: 0;
    transition: all 0.3s ease;
}

.sideNavBody-push {
    #{$sideNavDirection}: $sideNavWidth;
}

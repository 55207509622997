.post {
  padding: 30px 0;

  header {
    margin: 0 0 10px;
  }

  .entry-title {
    margin: 0 0 5px;

    a {
      border: 0;
    }
  }

  .entry-summary {
    p {
      margin: 15px 0 0;
    }
  }
}


.primary_pagination {
    margin: 30px 0 0 0;

    .current, a {
      border: 1px solid $brand-primary;
      padding: 7px 14px;
      font-size: 16px;
    }

    .current {
      background-color: $brand-primary;
    }

    a {
      transition: ease-in-out all .2s;

      &:hover {
          background-color: $brand-primary;
          color: $light-brown;
      }
    }
  }

.testimonials {
  padding: 50px 0;
  background-image: url('../images/bg_testimonials.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;

  .bordered-container {
    background-color: $faded-brown;
    border-color: rgba(#86604a, .9);

    &:before {
      border-color: rgba(#8f6a55, .9);
    }
  }

  h1 {
    margin: 0 0 15px;

    @include tablet {
      margin: 0 0 30px;
    }
  }

  .testimonial-slider {
    padding: 0 0 50px;

    @include laptop {
      width: 100%;
      margin: 0;
    }

    @include hd {
      padding: 0;
    }

    .slick-arrow {
      z-index: 1000;

      &:before {
        color: $light-brown;
        font-size: 30px;
      }

      &.slick-next, &.slick-prev {
        top: calc(50% + 60px);
        transform: translateY(calc(50% - 60px));
      }

      &.slick-next {
        right: -20px;

        @include hd {
          right: -35px;
        }
      }

      &.slick-prev {
        left: -30px;

        @include hd {
          left: -45px;
        }
      }
    }

    .slick-dots {
      bottom: 0;

      li button:before {
        color: $light-brown;
        font-size: 16px;
      }
    }

    .slick-track {
      display: flex;

      .slick-slide {
        display: flex;
        height: auto;
        outline: none;
        cursor: pointer;
      }
    }

    .testimonial-slide {
      position: relative;
      margin: 60px 5px 0;
      padding: 80px 15px 15px;
      background-color: $dark-brown;
      border: 2px solid $light-brown;
      border-radius: 3px;

      @include laptop {
        margin: 60px 10px 0;
        height: calc(100% - 60px);
      }

      @include desktop {
        padding: 80px 30px 30px;
      }

      @include hd {
        padding: 80px 50px 30px;
      }


      .testimonial-img {
        position: absolute;
        top: -60px;
        left: 50%;
        transform: translateX(-50%);
        width: 113px;
        height: 113px;
        border-radius: 50%;
        border: 2px solid $light-brown;
        background-image: url('../images/default_testimonial.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }

      .byline {
        margin: 0;
        font-size: 14px;
        color: $white;
      }
    }
  }
}


.footer-cta {
  position: relative;
  padding: 70px 0;
  background-image: url('../images/bg_footer_cta.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @include laptop {
    padding: 150px 0;
  }

  &:before {
    @include inset-border(2px, $light-brown);
    top: 2px;
  }

  .cta {
    padding: 30px;
    background-color: rgba($dark-brown, .3);
    border-color: rgba($white, .4);
    text-align: center;
    margin: 0;

    @include tablet {
      padding: 50px;
      text-align: left;
    }

    @include hd {
      margin: 0 145px 0 0;
    }

    &.bordered-container:before {
      background-color: rgba($dark-brown, .6);
      border-color: rgba($white, .4);
    }

    .heading {
      font-family: $primary-font;
      @include letter-spacing(50);
      font-size: 30px;
      line-height: 32px;

      @include laptop {
        font-size: 48px;
        line-height: 48px;
      }
    }

    .btn {
      margin: 5px 0 0;
    }
  }
}



.footer {
  position: relative;
  padding: 50px 0 15px;
  background-color: $black;
  text-align: center;

  @include laptop {
    text-align: left;
  }

  &:before {
    @include inset-border(2px, $light-brown);
    top: 2px;
  }

  p, a {
    font-size: 18px;
    line-height: 20px;
    font-family: $tertiary-font;
    margin: 0 0 23px;
  }

  a {
    color: $light-brown;
    border: 0;

    &:hover {
      border-bottom: 1px solid $light-brown;
    }
  }

  .logo {
    display: block;
    margin: 0 auto;
  }

  .quote {
    margin: 25px 0 10px;
    font-style: italic;
    font-size: 25px;
    font-weight: 800;
    text-align: center;
    @include letter-spacing(0);
    line-height: 24px;

    @include laptop {
      margin: 25px 0 0;
    }
  }

  .flex-footer {
    margin: 10px 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .text-btn {
      text-transform: uppercase;
      color: $white;
      font-weight: 700;

      &:hover {
        border-color: $white;
      }
    }

    .flex-container {
      @include flex(0,0,100%);
      margin: 15px 0 0;

      @include tablet {
        @include flex(0,0,33%);
      }

      @include laptop {
        @include flex(0,0,27%);
        margin: 0;
      }

      .title {
        text-transform: uppercase;
        font-weight: 800;
        margin: 0 0 10px;

        @include laptop {
          margin: 0 0 15px;
          font-size: 14px;
        }

        @include desktop {
          font-size: 18px;
        }
      }
    }
  }

  .footer-nav {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      line-height: 28px;

      @include laptop {
        line-height: 21px;
      }
    }
  }
}


.linksCopy {
  margin: 30px 0 0;

  p, a {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin: 0;
  }

  a:hover {
    border: 0;
  }
}
